/* eslint-disable indent */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { COLORS } from '@/constants';
import { applyRetinaImage } from '@/utils/retina';

interface LeftSidedProps {
  className?: string;
  heading: string;
  subsections: {
    heading?: string;
    text: string
  }[];
  backgroundImage: string;
  retinaBackgroundImage?: string;
  id?: string;
  scrollRefId?: string;
}

const LeftSided = ({ className, heading, subsections, id, scrollRefId }: LeftSidedProps): JSX.Element => (
  <div className={className}>
    { scrollRefId && (
      <div
        className="scroll-ref"
        id={scrollRefId}
      />
    ) }
    <div className="section-container">
      <div
        className="image"
        id={id}
      />
      <div className="content">
        <h2>{ heading }</h2>
        { subsections.map(({ heading: subheading, text }, index) => (
          <div
            key={`subsection-${index + 1}`}
            className="subsection"
          >
            { subheading ? <h3>{ subheading }</h3> : null }
            <p key={index}>{ text }</p>
          </div>
        )) }
      </div>
    </div>
  </div>
);

const StyledLeftSided = styled(LeftSided)`
  margin-bottom: 100px;
  position: relative;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }

  > .section-container {
    padding-left: 138px;
    background-image: ${({ backgroundImage }): string => `url('${backgroundImage}')`};
    background-size: cover;
    background-position-x: right;
    background-position-y: top;
    background-repeat: no-repeat;
    z-index: 1;

    ${({ backgroundImage, retinaBackgroundImage }): FlattenSimpleInterpolation => (
      applyRetinaImage(retinaBackgroundImage || backgroundImage)
    )};

    @media (max-width: 1679px) {
      background-position-x: -200px;
    }

    @media (max-width: 1399px) {
      background-position-x: -170px;
    }

    @media (max-width: 1199px) {
      padding-left: 70px;
      background-position-x: -230px;
    }

    @media (max-width: 991px) {
      padding-left: 36px;
      padding-right: 36px;
      background-position-x: 60%;
    }

    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
      background-image: none;
    }
  }

  .scroll-ref {
    position: absolute;
    top: -100px;

    @media (max-width: 991px) {
      top: -64px;
    }
  }

  .image {
    display: none;
    background-image: ${({ backgroundImage }): string => `url('${backgroundImage}')`};
    background-size: cover;
    background-position-x: right;
    background-repeat: no-repeat;
    z-index: 1;

    ${({ backgroundImage, retinaBackgroundImage }): FlattenSimpleInterpolation => (
      applyRetinaImage(retinaBackgroundImage || backgroundImage)
    )};

    @media (max-width: 767px) {
      display: block;
      height: 340px;
    }
  }
  
  .content {
    padding: 160px 0;
    max-width: 536px;

    @media (max-width: 1399px) {
      padding: 125px 0;
      max-width: 450px;
    }

    @media (max-width: 1199px) {
      padding: 115px 0;
    }

    @media (max-width: 991px) {
      padding: 100px 0;
      max-width: 48%;
    }

    @media (max-width: 767px) {
      padding-top: 24px;
      padding-bottom: 80px;
      padding-left: 36px;
      padding-right: 36px;
      max-width: unset;
    }

    h2 {
      &:after {
        display: block;
        margin: 24px 0 64px;
        content: "";
        width: 260px;
        max-width: 100%;
        height: 2px;
        background-color: ${COLORS.COLOR_PRIMARY};

        @media (max-width: 767px) {
          margin: 24px 0 44px;
        }

        @media (max-width: 374px) {
          max-width: 86%;
        }
      }
    }

    p {
      margin-bottom: 1.5rem;
      line-height: 1.5rem;
    }

    .subsection {
      &:last-of-type {
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  &.for-client-first {
    .content {
      h2 {
        max-width: 500px;

        @media (max-width: 767px) {
          max-width: 435px;
        }

        @media (max-width: 575px) {
          max-width: 345px;
        }
      }
    }
  }

  &.for-client-second {
    > .section-container {
      @media (max-width: 1679px) {
        background-position-x: -80px;
      }

      @media (max-width: 1399px) {
        background-position-x: -60px;
      }

      @media (max-width: 1199px) {
        background-position-x: -110px;
      }

      @media (max-width: 991px) {
        background-position-x: 35%;
      }
    }

    .image {
      @media (max-width: 575px) {
        background-position-x: 93%;
      }
    }

    .content {
      padding-top: 244px;
      padding-bottom: 244px;

      @media (max-width: 1399px) {
        padding-top: 185px;
        padding-bottom: 185px;
      }

      @media (max-width: 1199px) {
        padding-top: 175px;
        padding-bottom: 175px;
      }

      @media (max-width: 991px) {
        padding-top: 160px;
        padding-bottom: 160px;
        max-width: 51%;
      }

      @media (max-width: 767px) {
        padding-top: 24px;
        padding-bottom: 80px;
        max-width: unset;
      }
    }
  }

  &.for-client-third {
    > .section-container {
      @media (max-width: 1679px) {
        background-position-x: -140px;
        background-position-y: 0;
      }

      @media (max-width: 1399px) {
        background-position-x: -110px;
      }

      @media (max-width: 1199px) {
        background-position-x: -160px;
      }

      @media (max-width: 991px) {
        background-position-x: 59%;
      }
    }

    .image {
      @media (max-width: 575px) {
        background-position-x: 78%;
      }
    }

    .content {
      padding-top: 210px;
      padding-bottom: 210px;

      @media (max-width: 1399px) {
        padding-top: 170px;
        padding-bottom: 170px;
      }

      @media (max-width: 1199px) {
        padding-top: 160px;
        padding-bottom: 160px;
      }

      @media (max-width: 991px) {
        padding-top: 125px;
        padding-bottom: 125px;
        max-width: 38%;
      }

      @media (max-width: 767px) {
        padding-top: 24px;
        padding-bottom: 80px;
        max-width: unset;
      }

      h2 {
        max-width: unset;
      }
    }
  }
`;

export default StyledLeftSided;
