import scrollTo from 'gatsby-plugin-smoothscroll';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { VideoGradient, VideoPlaceholder } from '@/assets/images';
import { Checkmark } from '@/assets/svgs';
import { Video } from '@/assets/video';
import { StyledButtonWithArrow } from '@/components';
import SubmitApplicationButton from '@/components/Button/SubmitApplicationButton';
import { COLORS } from '@/constants';

interface BannerProps {
  className?: string;
}

const Banner = ({ className }: BannerProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div
      className={className}
      id="index-banner"
    >
      <div className="section-container">
        <div className="content">
          <h1 className="content-header">{ t('mainWindow:title') }</h1>
          <p>
            <Checkmark />
            { t('mainWindow:reason-first') }
          </p>
          <p>
            <Checkmark />
            { t('mainWindow:reason-second') }
          </p>
          <p>
            <Checkmark />
            { t('mainWindow:reason-third') }
          </p>
          <p>
            <Checkmark />
            { t('mainWindow:reason-fourth') }
          </p>
          <div className="buttons">
            <StyledButtonWithArrow
              className="button-learn-more"
              color="secondary"
              direction="down"
              onClick={(): void => { scrollTo('#how-it-works'); }}
              title={t('common:button.learn-more')}
            />
            <SubmitApplicationButton className="button-submit-request" />
          </div>
        </div>
      </div>
      <div className="video-container">
        <video
          autoPlay
          className="video"
          loop
          muted
          playsInline
          poster={VideoPlaceholder}
        >
          <source
            src={Video}
            type="video/mp4"
          />
        </video>
      </div>
      <div className="video-gradient" />
    </div>
  );
};

const StyledBanner = styled(Banner)`
  position: relative;
  width: 100%;
  height: 100%;

  @media (min-width: 992px) {
    min-height: 768px;
  }

  > .section-container {
    height: 100%;
    padding-left: 138px;
    position: relative;
  }

  .video-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0px;
    left: 0px;
  }

  .video-container video {
    object-fit: cover;
    height: 100%;
    min-width: 100%;
    position: relative;
  }

  .video-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-image: url('${VideoGradient}');
    background-size: cover;
    top: 0px;
    left: 0px;
  }

  .content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    color: ${COLORS.TEXT_PRIMARY};
    max-width: 678px;

    p {
      font-size: 1rem;
      margin-bottom: 1rem;
      display: flex;
      text-shadow: ${COLORS.SHADOWS.BANNER_PARAGRAPH};
      font-weight: 600;

      > :first-of-type {
        margin-right: 16px;
      }
    }

    .buttons {
      display: flex;
      margin-top: 100px;

      > :first-of-type {
        margin-right: 26px;
      }
    }

    .content-header {
      text-shadow: ${COLORS.SHADOWS.BANNER_HEADER};
    }
  }

  @media (max-width: 1199px) {
    > .section-container {
      padding-left: 70px;
    }
  }

  @media (max-width: 991px) {
    > .section-container {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      max-width: 582px;
    }
  
    .content {
      max-width: 582px;
      padding-left: 36px;
      padding-right: 36px;
    }

    .content-header {
      font-size: 2.6rem;
    }
  }

  @media (max-width: 767px) {
    .content-header {
      font-size: 2.3rem;
    }
  }

  @media (max-width: 575px) {
    > .section-container {
      padding-left: 0;
      padding-right: 0;
      max-width: 100%;
      position: relative;
    }

    .content {
      top: 27%;
      transform: none;
      bottom: 0;
      margin-top: 0;
      max-width: 100%;
      padding-left: 36px;
      padding-right: 36px;
    
      .buttons {
        margin-top: 0;
        padding-top: 80px;
        position: absolute;
        left: 36px;
        bottom: 40px;
        
        .button-learn-more {
          margin-right: 30px;

          .button {
            display: none;
          }
        }
      }
    }
    
    .content-header {
      font-size: 1.71rem;
      margin-bottom: 2.42rem;
    }
  }

  @media (max-width: 374px) {
    .content {
      .content-header {
        font-size: 1.5rem;
        margin-bottom: 2.22rem;
      }

      .buttons {
        .arrow-box {
          width: 50px;
          height: 50px;

          svg {
            width: 25px;
            height: 25px;
          }
        }

        .button-learn-more {
          margin-right: 20px;
        }

        .button-submit-request {
          .button {
            height: 50px;
            padding-top: 16px;
            padding-bottom: 16px;
            padding-left: 14px;
            padding-right: 14px;
            font-size: 12px;
          }
        }
      }
    }

    @media (max-width: 374px) and (max-height: 599px) {
      .content {
        top: 15%;
      }
    }
  }
`;

export default StyledBanner;
