import React, { useState } from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';

import { COLORS } from '@/constants';

const ANIMATION_CONFIG = {
  DURATION_SHORT: 2.2,
  DURATION_LONG: 3.5,
  START: 10,
};

interface StatisticsProps {
  className?: string;
}

const Statistics = ({ className }: StatisticsProps): JSX.Element => {
  const { t } = useTranslation();
  const [isVisibilitySensorActive, setIsVisibilitySensorActive] = useState(false);

  const STATISTICS_ENDS = {
    FIRST: Number(t('statistics:sections.first.count')),
    SECOND: Number(t('statistics:sections.second.count')),
    THIRD: Number(t('statistics:sections.third.count')),
  };

  const onVisibleChange = (isVisible: boolean): void => {
    if (isVisible) {
      setIsVisibilitySensorActive(true);
    }
  };

  return (
    <div className={className}>
      <div className="section-container">
        <div className="content">
          <div className="section">
            <p>{ t('statistics:sections.first.title') }</p>
            <CountUp
              duration={ANIMATION_CONFIG.DURATION_LONG}
              end={isVisibilitySensorActive ? STATISTICS_ENDS.FIRST : 0}
              start={ANIMATION_CONFIG.START}
            >
              { ({ countUpRef }): JSX.Element => (
                <p ref={countUpRef} />
              ) }
            </CountUp>
            <p>{ t('statistics:sections.first.description') }</p>
          </div>
          <div className="section">
            <p>{ t('statistics:sections.second.title') }</p>
            <CountUp
              duration={ANIMATION_CONFIG.DURATION_SHORT}
              end={isVisibilitySensorActive ? STATISTICS_ENDS.SECOND : 0}
              // TODO: Add regex to some constants with other expressions
              formattingFn={(value: number): string => value.toString().replace(/(?!^)(?=(?:\d{3})+(?:$))/gm, ' ')}
              start={ANIMATION_CONFIG.START}
            >
              { ({ countUpRef }): JSX.Element => (
                <VisibilitySensor onChange={onVisibleChange}>
                  <p ref={countUpRef} />
                </VisibilitySensor>
              ) }
            </CountUp>
            <p>{ t('statistics:sections.second.description') }</p>
          </div>
          <div className="section">
            <p>{ t('statistics:sections.third.title') }</p>
            <CountUp
              duration={ANIMATION_CONFIG.DURATION_SHORT}
              end={isVisibilitySensorActive ? STATISTICS_ENDS.THIRD : 0}
              start={ANIMATION_CONFIG.START}
              suffix={t('statistics:sections.third.suffix')}
            >
              { ({ countUpRef }): JSX.Element => (
                <p ref={countUpRef} />
              ) }
            </CountUp>
            <p>{ t('statistics:sections.third.description') }</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const StyledStatistics = styled(Statistics)`
  padding: 140px 0;

  @media (max-width: 1399px) {
    padding: 120px 0;
  }

  @media (max-width: 991px) {
    padding: 100px 0;
  }

  @media (max-width: 767px) {
    padding: 60px 0;
  }

  > .section-container {
    margin: auto;
    position: relative;
    display: flex;
    place-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .section {
      width: 33.3333%;
      padding-right: 16px;
      text-align: center;

      @media (max-width: 991px) {
        padding-right: 50px
      }

      @media (max-width: 767px) {
        width: 100%;
        padding-right: 0;
        margin-bottom: 40px;
      }

      &:last-child {
        padding-right: 0;

        @media (max-width: 767px) {
          margin-bottom: 0;
        }
      }
    }
  }

  p {
    font-family: "Roboto Slab";
    font-size: 24px;
    font-weight: normal;
    line-height: 1.5;

    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 1.4;
    }

    :nth-of-type(2) {
      font-size: 48px;
      font-weight: bold;
      line-height: 1.33;
      color: ${COLORS.COLOR_SECONDARY};

      @media (max-width: 991px) {
        font-size: 40px;
      }

      @media (max-width: 767px) {
        font-size: 34px;
      }
    }
  }
`;

export default StyledStatistics;
