import { COLORS } from '@/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface HowItWorksProps {
  className?: string;
  id?: string;
}

const SECTIONS = [['project', 'contact'], ['offer', 'decision']];

const HowItWorks = ({ className, id }: HowItWorksProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div
        className="scroll-to"
        id={id}
      />
      <div className="section-container">
        <div className="heading-container">
          <h2>{ t('howItWorks:title') }</h2>
          <p>{ t('howItWorks:description') }</p>
        </div>
        <div className="details-container">
          {
            SECTIONS.map((pairs, pairIndex) => (
              pairs.map((singleSection, index) => (
                <div
                  key={`step-${pairIndex + 1}-${index + 1}`}
                  className={`section step-${index + 1}`}
                >
                  <div className="flex-container">
                    <div className="order-section">
                      { `${pairIndex * 2 + index + 1}` }
                    </div>
                    <div className="content-section">
                      <h3 className="content-section-title">{ t(`howItWorks:sections.${singleSection}.title`) }</h3>
                      <p>{ t(`howItWorks:sections.${singleSection}.description`) }</p>
                    </div>
                  </div>
                </div>
              ))
            ))
          }
        </div>
      </div>
    </div>
  );
};

const StyledHowItWorks = styled(HowItWorks)`
  background-color: ${COLORS.COLOR_SECONDARY};
  color: ${COLORS.TEXT_PRIMARY};
  position: relative;
  padding: 120px 0;

  @media (max-width: 1399px) {
    padding: 100px 0;
  }

  @media (max-width: 767px) {
    padding: 60px 0;
  }

  .scroll-to {
    position: absolute;
    top: -100px;

    @media (max-width: 991px) {
      top: -64px;
    }
  }

  .heading-container {
    max-width: 950px;
    padding-left: 138px;

    @media (max-width: 1199px) {
      padding-left: 70px;
    }

    @media (max-width: 991px) {
      padding-left: 0;
    }

    @media (max-width: 767px) {
      padding-left: 0;
    }
  }

  .content-section-title {
    font-weight: bold;
    margin-bottom: 0.375rem;

    @media (max-width: 767px) {
      margin-bottom: 0.6rem;
    }

    @media (max-width: 575px) {
      margin-bottom: 1.2rem;
    }
  }

  .details-container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 80px;
    padding-left: 44px;
    
    @media (max-width: 1399px) {
      margin-top: 50px;
      padding-left: 138px;
    }

    @media (max-width: 1199px) {
      padding-left: 70px;
    }

    @media (max-width: 991px) {
      padding-left: 0;
    }

    @media (max-width: 767px) {
      margin-top: 40px;
      padding-left: 0;
    }

    .section {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      flex-basis: 25%;
      width: 25%;
      height: 220px;
      position: relative;
      padding-right: 60px;

      @media (max-width: 1679px) {
        padding-right: 30px;
      }

      @media (max-width: 1399px) {
        width: 50%;
        flex-basis: 50%;
        padding-left: 45px;
        padding-right: 60px;
        padding-bottom: 30px;
        height: 130px;
      }

      @media (max-width: 991px) {
        padding-left: 32px;
        padding-right: 30px;
        padding-bottom: 0;
        height: unset;
      }

      @media (max-width: 767px) {
        width: 100%;
        flex-basis: 100%;
        padding-left: 32px;
        padding-right: 0;
        max-width: unset;
      }

      &.step-1 {
        @media (max-width: 1399px) {
          order: 1;
        }
      }

      &.step-2 {
        @media (max-width: 1399px) {
          order: 2;
          padding-bottom: 0;

          &::before {
            content: none;
          }
        }

        @media (max-width: 767px) {
          &::before {
            content: '';
          }
        }
      }

      &.step-3 {
        @media (max-width: 1399px) {
          order: 3;
        }
      }

      &.step-4 {
        @media (max-width: 1399px) {
          order: 4;
          height: 100px;
          padding-bottom: 0;

          &::before {
            content: none;
          }
        }
      }

      &.step-1,
      &.step-2,
      &.step-3,
      &.step-4 {
        @media (max-width: 767px) {
          order: unset;
        }
      }

      &::before {
        content: '';
        height: 2px;
        background-color: #fff;
        bottom: 16px;
        left: 17px;
        right: 0;
        position: absolute;

        @media (max-width: 1399px) {
          left: -1px;
          top: 48px;
          width: 2px;
          height: 100%;
        }

        @media (max-width: 991px) {
          left: -1px;
          top: 36px;
        }
      }

      &:last-child::before {
        content: none;
      }

      &::after {
        content: '';
        width: 34px;
        height: 34px;
        background-color: ${COLORS.COLOR_PRIMARY};
        border: 4px solid ${COLORS.COLOR_SECONDARY};
        bottom: 0;
        position: absolute;
        border-radius: 50%;

        @media (max-width: 1399px) {
          left: -17px;
          top: 32px;
        }

        @media (max-width: 991px) {
          width: 24px;
          height: 24px;
          left: -12px;
          top: 24px;
        }
      }

      .flex-container {
        display: flex;
      }

      .order-section {
        font-size: 48px;
        font-family: "Roboto Slab";
        margin-right: 16px;
        font-weight: bold;
        width: 78px;
        flex-shrink: 0;

        @media (max-width: 1399px) {
          width: 60px;
        }

        @media (max-width: 991px) {
          width: 36px;
          font-size: 34px;
          margin-right: 10px;
        }
      }

      .content-section {
        padding-top: 10px;

        @media (max-width: 991px) {
          padding-bottom: 30px;
        }

        @media (max-width: 767px) {
          padding-top: 0;
          padding-bottom: 40px;
        }
      }
    }
  }
`;

export default StyledHowItWorks;
