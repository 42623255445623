import { PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import {
  ForClientFirst,
  ForClientFirstRetina,
  ForClientSecond,
  ForClientSecondRetina,
  ForClientThird,
  ForClientThirdRetina,
} from '@/assets/images';
import { WhyFintelli } from '@/components';
import StyledFooter from '@/components/Footer';
import StyledBanner from '@/components/Sections/Client/Banner';
import StyledHowItWorks from '@/components/Sections/Client/HowItWorks';
import StyledStatistics from '@/components/Sections/Client/Statistics';
import StyledContactForm from '@/components/Sections/ContactForm';
import StyledLeftSided from '@/components/Sections/LeftSided';
import StyledNavigationBar from '@/components/NavigationBar';
import Layout from '@/styles/index';

const Main = ({ location }: PageProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{ t('common:page-titles.index') }</title>
      </Helmet>
      <Layout>
        <div
          className="dummy"
          id="banner"
        />
        <StyledNavigationBar
          className="navbar"
          initialValue="FOR_CLIENT"
          isClientPage
        />
        <StyledBanner />
        <StyledHowItWorks id="how-it-works" />
        <StyledStatistics />
        <div className="left-sides">
          <StyledLeftSided
            backgroundImage={ForClientFirst}
            className="for-client-first"
            heading={t('mostAdvantageousOffer:title')}
            retinaBackgroundImage={ForClientFirstRetina}
            subsections={[
              { text: t('mostAdvantageousOffer:description:first') },
              { text: t('mostAdvantageousOffer:description:second') },
              { text: t('mostAdvantageousOffer:description:third') },
            ]}
          />
          <StyledLeftSided
            backgroundImage={ForClientSecond}
            className="for-client-second"
            heading={t('fullyDigitalProcess:title')}
            retinaBackgroundImage={ForClientSecondRetina}
            subsections={[
              { text: t('fullyDigitalProcess:description:first') },
              { text: t('fullyDigitalProcess:description:second') },
            ]}
          />
          <StyledLeftSided
            backgroundImage={ForClientThird}
            className="for-client-third"
            heading={t('returnOnInvestment:title')}
            retinaBackgroundImage={ForClientThirdRetina}
            subsections={[
              { text: t('returnOnInvestment:description:first') },
              { text: t('returnOnInvestment:description:second') },
            ]}
          />
        </div>
        <WhyFintelli />
        <StyledContactForm
          id="contact"
          location={location}
        />
        <StyledFooter />
      </Layout>
    </>
  );
};

export default Main;
